import {
  Box,
  Button,
  Group,
  NumberInput,
  Paper,
  Skeleton,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  departDateState,
  departScheduleState,
  returnDateState,
  returnScheduleState,
  selectedDepartScheduleState,
  selectedReturnScheduleState,
  soldScheduleState,
  visibleDepartState,
  visibleReturnState,
} from '../../models/scheduleState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAirlineInfo, getRawSelectedSchedules } from '../../utils/scheduleHelper';
import { formatPrice, isEqual, isNil, uniq } from '../../utils/helper';
import { passengerPrice } from '../../utils/checkoutHelpers';
import {
  useDidUpdate,
  useMediaQuery,
  usePrevious,
  useSetState,
  useViewportSize,
} from '@mantine/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import {
  getBookingDetailInfo,
  refreshSchedule,
  updateAvailableSchedule,
} from '../../services/checkout';
import dayjs from 'dayjs';
import { useCountdownTimer } from '../../hooks/useCountdownTimer';
// @ts-ignore
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { primaryColor } from '../../assets/themes/theme';

export default function CheckoutTable() {
  const { width } = useViewportSize();
  const isMobile = useMediaQuery('(max-width: 37.5rem)');
  const isTablet = useMediaQuery('(min-width: 37.5rem) and (max-width: 64rem)');

  const { i18n, t } = useTranslation();

  const [soldSchedule, setSoldSchedule] = useRecoilState(soldScheduleState);
  const selectedDepart = useRecoilValue(selectedDepartScheduleState);
  const selectedReturn = useRecoilValue(selectedReturnScheduleState);
  const departDate = useRecoilValue(departDateState);
  const returnDate = useRecoilValue(returnDateState);
  const visibleDepart = useRecoilValue(visibleDepartState);
  const visibleReturn = useRecoilValue(visibleReturnState);
  const departSchedule = useRecoilValue(departScheduleState);
  const returnSchedule = useRecoilValue(returnScheduleState);

  const isVisibleDepart = useMemo(() => visibleDepart === 'true', [visibleDepart]);
  const isVisibleReturn = useMemo(() => visibleReturn === 'true', [visibleReturn]);

  const [bookingData, setBookingData] = useState<any>(null);
  const [resetSlide, setResetSlide] = useState(0);
  const [passengerAmount, setPassengerAmount] = useSetState<any>({
    departure: {
      adults: 1,
      children: 0,
      infants: 0,
      checkedBags: 0,
    },
    return: {
      adults: 1,
      children: 0,
      infants: 0,
      checkedBags: 0,
    },
  });

  const passengerAmountPrevious = usePrevious(passengerAmount);
  useDidUpdate(() => {
    if (
      passengerAmountPrevious &&
      !isEqual(passengerAmount.departure, passengerAmountPrevious.departure)
    ) {
      setPassengerAmount(() => ({
        return: passengerAmount.departure,
      }));
    }
    if (
      passengerAmountPrevious &&
      !isEqual(passengerAmount.return, passengerAmountPrevious.return)
    ) {
      setPassengerAmount(() => ({
        departure: passengerAmount.return,
      }));
    }
  }, [passengerAmount]);

  const customDeepLink = useMemo(() => {
    if (!bookingData?.details?.deep_link) return '';
    const deepLink = new URL(bookingData.details.deep_link);
    const deepLinkParams = new URLSearchParams(deepLink.search);
    deepLinkParams.set(
      'passengers',
      `${passengerAmount.departure.adults}-0-${passengerAmount.departure.children}-${passengerAmount.departure.infants}`,
    );
    return deepLink.origin + deepLink.pathname + '?' + deepLinkParams;
  }, [bookingData, passengerAmount]);

  useEffect(() => {
    if (isNil(bookingData)) return;
    setBookingData(null);
    setResetSlide((counter) => counter + 1);
  }, [selectedDepart, selectedReturn, visibleDepart, visibleReturn]);

  const handleSetSoldSchedule = useCallback(
    (newData: string[]) => {
      const newState = uniq([...soldSchedule, ...newData]);
      setSoldSchedule(newState);
      Cookies.set('dacota-sold-schedule', JSON.stringify(newState));
    },
    [setSoldSchedule],
  );

  const timeLeft = useCountdownTimer({
    expiryDate: isNil(bookingData) ? null : bookingData?.expired_at,
    shouldClear: isNil(bookingData),
    onFinish: () => {
      setBookingData(null);
      setResetSlide((counter) => counter + 1);
    },
  });

  const { data: airlineInfoDepart, isLoading: isLoadingAirlineInfoDepart } = useQuery({
    queryKey: ['airline-info-depart', selectedDepart],
    queryFn: async () => {
      let info: any = [];
      const resAirline: any = await getBookingDetailInfo({
        id: selectedDepart?.operating_carrier,
        type: 'airline',
      });
      if (resAirline?.statusCode === 200) {
        info.push(resAirline?.data.rows.map((val: any) => val?.desc).join(', '));
      }
      const resAirport: any = await getBookingDetailInfo({
        id: selectedDepart?.flyFrom,
        type: 'airport',
      });
      if (resAirport?.statusCode === 200) {
        info.push(resAirport?.data.rows.map((val: any) => val?.desc).join(', '));
      }
      return info;
    },
    enabled: isVisibleDepart && !isNil(selectedDepart?.operating_carrier),
    retry: 1,
  });

  const { data: airlineInfoReturn, isLoading: isLoadingAirlineInfoReturn } = useQuery({
    queryKey: ['airline-info-return', selectedReturn],
    queryFn: async () => {
      let info: any = [];
      const resAirline: any = await getBookingDetailInfo({
        id: selectedReturn?.operating_carrier,
        type: 'airline',
      });
      if (resAirline?.statusCode === 200) {
        info.push(resAirline?.data.rows.map((val: any) => val?.desc).join(', '));
      }
      const resAirport: any = await getBookingDetailInfo({
        id: selectedReturn?.flyFrom,
        type: 'airport',
      });
      if (resAirport?.statusCode === 200) {
        info.push(resAirport?.data.rows.map((val: any) => val?.desc).join(', '));
      }
      return info;
    },
    enabled: isVisibleReturn && !isNil(selectedReturn?.operating_carrier),
    retry: 1,
  });

  const { mutate: onRefreshBooking, isPending: isPendingBooking } = useMutation({
    mutationKey: [
      'refresh-booking',
      selectedReturn,
      selectedDepart,
      isVisibleReturn,
      isVisibleDepart,
    ],
    mutationFn: async () => {
      let body: any = {
        item: {},
        date: departDate,
      };

      if (isVisibleDepart && isVisibleReturn) {
        body.item = {
          ...selectedDepart,
        };
        body.date = departDate;
        body.returnDate = returnDate;
      } else if (isVisibleDepart) {
        body.item = {
          ...selectedDepart,
        };
        body.date = departDate;
      } else if (isVisibleReturn) {
        body.item = {
          ...selectedReturn,
        };
        body.date = returnDate;
      }

      return await refreshSchedule(body);
    },
    onSuccess: (res) => {
      let id: string = '';
      if (isVisibleDepart && isVisibleReturn) {
        id = `${selectedDepart?.id}|${selectedReturn?.id}`;
      } else if (isVisibleDepart && !isVisibleReturn) {
        id = selectedDepart?.id;
      } else if (!isVisibleDepart && isVisibleReturn) {
        id = selectedReturn?.id;
      }
      // Check all flight schedule availability
      const sold = updateAvailableSchedule({
        realSchedule: res?.data?.rows.map((doc: any) => doc.id) || [],
        cacheSchedule: [...departSchedule, ...returnSchedule],
      });
      if (sold.length) handleSetSoldSchedule(sold);
      setTimeout(() => {
        // Check selected item availability
        const items = res?.data?.rows.find((item: { id: string }) => item?.id === id);
        if (isNil(items)) {
          // Set sold and select another schedules
          notifications.show({
            id: 'undefined-schedules',
            color: 'red',
            title: t('no_longer_available'),
            message: t('no_longer_available_description'),
            autoClose: 10000,
          });
          setResetSlide((counter) => counter + 1);
          return;
        }
        const selectedSchedule = getRawSelectedSchedules(items);
        setBookingData({ ...selectedSchedule, expired_at: dayjs().add(5, 'minute') });
        Cookies.set('dacota-last-refresh-prices', dayjs().toISOString(), {
          expires: dayjs().add(1, 'day').startOf('day').toDate(),
        });
      }, 1000);
    },
    onError: (error) => {
      notifications.show({
        id: 'failed-schedules',
        color: 'red',
        title: 'Something wrong!',
        message: error?.message,
      });
      setResetSlide((counter) => counter + 1);
    },
  });

  const airlineInfo = useCallback(
    (type: string) => getAirlineInfo(type === 'depart' ? selectedDepart : selectedReturn),
    [selectedDepart, selectedReturn],
  );

  /**
   * get total depart price
   */
  const totalDepart = useMemo(() => {
    if (!isVisibleDepart) return 0;
    const adultPrice = passengerPrice('adults', selectedDepart) * passengerAmount.departure.adults;
    const childrenPrice =
      passengerPrice('children', selectedDepart) * passengerAmount.departure.children;
    const infantPrice =
      passengerPrice('infants', selectedDepart) * passengerAmount.departure.infants;
    const checkedBagPrice =
      passengerPrice('checkedBags', selectedDepart) * passengerAmount.departure.checkedBags;
    const airportFee = 0;

    return adultPrice + childrenPrice + infantPrice + checkedBagPrice + airportFee;
  }, [selectedDepart, isVisibleDepart, passengerAmount]);

  /**
   * get total return price
   */
  const totalReturn = useMemo(() => {
    if (!isVisibleReturn) return 0;
    const adultPrice = passengerPrice('adults', selectedReturn) * passengerAmount.return.adults;
    const childrenPrice =
      passengerPrice('children', selectedReturn) * passengerAmount.return.children;
    const infantPrice = passengerPrice('infants', selectedReturn) * passengerAmount.return.infants;
    const checkedBagPrice =
      passengerPrice('checkedBags', selectedReturn) * passengerAmount.return.checkedBags;
    const airportFee = 0;

    return adultPrice + childrenPrice + infantPrice + checkedBagPrice + airportFee;
  }, [selectedReturn, isVisibleReturn, passengerAmount]);

  const getTotalPrice = useMemo(() => {
    return totalDepart + totalReturn;
  }, [totalDepart, totalReturn]);

  const onRefreshPrices = useCallback(() => {
    onRefreshBooking();
  }, [onRefreshBooking]);

  /**
   * know how long the trip will take
   */
  const dayInterval = useMemo(() => {
    if (isNil(selectedReturn)) return 0;
    if (isNil(selectedDepart)) return 0;
    return dayjs
      .utc(selectedReturn?.local_arrival)
      .add(1, 'day')
      .diff(dayjs.utc(selectedDepart?.local_departure), 'day');
  }, [selectedDepart, selectedReturn]);

  const isSelectedSchedule = useMemo(() => {
    if (
      isVisibleDepart &&
      isVisibleReturn &&
      (!Object.keys(selectedDepart).length || !Object.keys(selectedReturn).length)
    )
      return false;
    else if (isVisibleDepart && !Object.keys(selectedDepart).length) return false;
    else if (isVisibleReturn && !Object.keys(selectedReturn).length) return false;
    return true;
  }, [selectedDepart, selectedReturn, isVisibleDepart, isVisibleReturn]);

  const refreshDate = useMemo(() => {
    const data = Cookies.get('dacota-last-refresh-prices');
    if (isNil(data)) return '';
    return `${t('updated_label')} ${dayjs(data).locale(i18n?.language).fromNow()}`;
  }, [bookingData, i18n?.language]);

  const bookingItems = useMemo(() => {
    return [
      {
        isVisible: isVisibleDepart && Object.keys(selectedDepart).length,
        isLoading: isLoadingAirlineInfoDepart,
        airlineInfo: airlineInfoDepart,
        item: selectedDepart,
        type: 'departure',
      },
      {
        isVisible: isVisibleReturn && Object.keys(selectedReturn).length,
        isLoading: isLoadingAirlineInfoReturn,
        airlineInfo: airlineInfoReturn,
        item: selectedReturn,
        type: 'return',
      },
    ];
  }, [
    isLoadingAirlineInfoDepart,
    isVisibleDepart,
    airlineInfoDepart,
    selectedDepart,
    isLoadingAirlineInfoReturn,
    isVisibleReturn,
    airlineInfoReturn,
    selectedReturn,
  ]);

  return (
    <Stack gap="xs">
      {isSelectedSchedule ? (
        <Text fw="600" fz="md">
          {t('book_flight_label')}
        </Text>
      ) : (
        <Skeleton w={200}>
          <Box h={32} />
        </Skeleton>
      )}
      {isSelectedSchedule ? (
        <Paper shadow="xs">
          <Table.ScrollContainer type="native" minWidth={width >= 960 ? '100%' : 960}>
            <Table className="table-checkout">
              <Table.Tbody>
                {bookingItems.map((booking, index) =>
                  !booking.isVisible ? null : (
                    <Table.Tr key={index}>
                      {isMobile || isTablet ? (
                        <>
                          <Table.Td align="center" w={120} bg="white">
                            <Text fz="sm">
                              {formatPrice(passengerPrice('adults', booking?.item))}
                            </Text>
                          </Table.Td>
                          <Table.Td w={260}>
                            <Stack gap={0}>
                              <Group justify="space-between">
                                <NumberInput
                                  w="3rem"
                                  size="xs"
                                  min={1}
                                  value={passengerAmount[booking.type].adults}
                                  onChange={(value) =>
                                    setPassengerAmount((current) => ({
                                      [booking.type]: {
                                        ...current[booking.type],
                                        adults: value as number,
                                      },
                                    }))
                                  }
                                />
                                <Text fz="sm">
                                  {`(${formatPrice(passengerPrice('adults', booking?.item))})`}{' '}
                                  {t('adults_passenger_label')}
                                </Text>
                              </Group>
                              <Group justify="space-between">
                                <NumberInput
                                  w="3rem"
                                  size="xs"
                                  min={0}
                                  value={passengerAmount[booking.type].children}
                                  onChange={(value) =>
                                    setPassengerAmount((current) => ({
                                      [booking.type]: {
                                        ...current[booking.type],
                                        children: value as number,
                                      },
                                    }))
                                  }
                                />
                                <Text fz="sm">
                                  {`(${formatPrice(passengerPrice('children', booking?.item))})`}{' '}
                                  {t('children_under_12_passenger_label')}
                                </Text>
                              </Group>
                              <Group justify="space-between">
                                <NumberInput
                                  w="3rem"
                                  size="xs"
                                  min={0}
                                  value={passengerAmount[booking.type].infants}
                                  onChange={(value) =>
                                    setPassengerAmount((current) => ({
                                      [booking.type]: {
                                        ...current[booking.type],
                                        infants: value as number,
                                      },
                                    }))
                                  }
                                />
                                <Text fz="sm">
                                  {`(${formatPrice(passengerPrice('infants', booking?.item))})`}{' '}
                                  {t('children_under_2_passenger_label')}
                                </Text>
                              </Group>
                              <Group justify="space-between">
                                <NumberInput
                                  w="3rem"
                                  size="xs"
                                  min={0}
                                  value={passengerAmount[booking.type].checkedBags}
                                  onChange={(value) =>
                                    setPassengerAmount((current) => ({
                                      [booking.type]: {
                                        ...current[booking.type],
                                        checkedBags: value as number,
                                      },
                                    }))
                                  }
                                />
                                <Text fz="sm">
                                  {`(${formatPrice(passengerPrice('checkedBags', booking?.item))})`}{' '}
                                  {t('checked_bags_label')}
                                </Text>
                              </Group>
                            </Stack>
                          </Table.Td>
                          <Table.Td w={120} pos="sticky" left={0} bg="white">
                            <Text fz="sm">
                              {`${dayjs.utc(booking?.item?.local_departure).locale(i18n.language).format('dddd HH:mm')}`}
                            </Text>
                          </Table.Td>
                          <Table.Td w={64}>
                            <Text fz="sm">{booking?.item?.flyFrom}</Text>
                          </Table.Td>
                          <Table.Td>
                            {booking?.isLoading ? (
                              <Skeleton />
                            ) : isNil(booking?.airlineInfo) ? null : (
                              <Group gap="4">
                                {booking?.airlineInfo.map((val: any, index_airline: number) =>
                                  val === '' ? null : (
                                    <Text key={`depart_${index_airline}`} fz="sm">
                                      {val}
                                    </Text>
                                  ),
                                )}
                              </Group>
                            )}
                          </Table.Td>
                        </>
                      ) : (
                        <>
                          <Table.Td>
                            <Stack gap={0} h={112} justify="center">
                              {booking?.isLoading ? (
                                <Skeleton />
                              ) : isNil(booking?.airlineInfo) ? null : (
                                <Group gap="4">
                                  {booking?.airlineInfo.map((val: any, index_airline: number) =>
                                    val === '' ? null : (
                                      <Text key={`depart_${index_airline}`} fz="sm">
                                        {val}
                                      </Text>
                                    ),
                                  )}
                                </Group>
                              )}
                            </Stack>
                          </Table.Td>
                          <Table.Td w={180}>
                            <Stack gap={0} h={112} justify="center">
                              <Text fz="sm">
                                {`${dayjs.utc(booking?.item?.local_departure).locale(i18n.language).format('dddd HH:mm')}`}
                              </Text>
                            </Stack>
                          </Table.Td>
                          <Table.Td w={64}>
                            <Stack gap={0} h={112} justify="center">
                              <Text fz="sm">{booking?.item?.flyFrom}</Text>
                            </Stack>
                          </Table.Td>
                          <Table.Td w={300}>
                            <Stack gap={isVisibleDepart && isVisibleReturn && index > 0 ? 7 : 0}>
                              <Group justify="space-between">
                                <Text fz="sm">
                                  {t('adults_passenger_label')}{' '}
                                  {`(${formatPrice(passengerPrice('adults', booking?.item))})`}
                                </Text>
                                <NumberInput
                                  w="3rem"
                                  size="xs"
                                  min={1}
                                  value={passengerAmount[booking.type].adults}
                                  onChange={(value) =>
                                    setPassengerAmount((current) => ({
                                      [booking.type]: {
                                        ...current[booking.type],
                                        adults: value as number,
                                      },
                                    }))
                                  }
                                  {...(isVisibleDepart &&
                                    isVisibleReturn &&
                                    index > 0 && { style: { display: 'none' } })}
                                />
                              </Group>
                              <Group justify="space-between">
                                <Text fz="sm">
                                  {t('children_under_12_passenger_label')}{' '}
                                  {`(${formatPrice(passengerPrice('children', booking?.item))})`}
                                </Text>
                                <NumberInput
                                  w="3rem"
                                  size="xs"
                                  min={0}
                                  value={passengerAmount[booking.type].children}
                                  onChange={(value) =>
                                    setPassengerAmount((current) => ({
                                      [booking.type]: {
                                        ...current[booking.type],
                                        children: value as number,
                                      },
                                    }))
                                  }
                                  {...(isVisibleDepart &&
                                    isVisibleReturn &&
                                    index > 0 && { style: { display: 'none' } })}
                                />
                              </Group>
                              <Group justify="space-between">
                                <Text fz="sm">
                                  {t('children_under_2_passenger_label')}{' '}
                                  {`(${formatPrice(passengerPrice('infants', booking?.item))})`}
                                </Text>
                                <NumberInput
                                  w="3rem"
                                  size="xs"
                                  min={0}
                                  value={passengerAmount[booking.type].infants}
                                  onChange={(value) =>
                                    setPassengerAmount((current) => ({
                                      [booking.type]: {
                                        ...current[booking.type],
                                        infants: value as number,
                                      },
                                    }))
                                  }
                                  {...(isVisibleDepart &&
                                    isVisibleReturn &&
                                    index > 0 && { style: { display: 'none' } })}
                                />
                              </Group>
                              <Group justify="space-between">
                                <Text fz="sm">
                                  {t('checked_bags_label')}{' '}
                                  {`(${formatPrice(passengerPrice('checkedBags', booking?.item))})`}
                                </Text>
                                <NumberInput
                                  w="3rem"
                                  size="xs"
                                  min={0}
                                  value={passengerAmount[booking.type].checkedBags}
                                  onChange={(value) =>
                                    setPassengerAmount((current) => ({
                                      [booking.type]: {
                                        ...current[booking.type],
                                        checkedBags: value as number,
                                      },
                                    }))
                                  }
                                  {...(isVisibleDepart &&
                                    isVisibleReturn &&
                                    index > 0 && { style: { display: 'none' } })}
                                />
                              </Group>
                            </Stack>
                          </Table.Td>
                          <Table.Td align="center" w={120}>
                            <Text fz="sm">
                              {booking.type === 'departure'
                                ? formatPrice(totalDepart)
                                : formatPrice(totalReturn)}
                            </Text>
                          </Table.Td>
                        </>
                      )}
                    </Table.Tr>
                  ),
                )}
                {(isVisibleDepart || isVisibleReturn) && (
                  <Table.Tr>
                    {isMobile || isTablet ? (
                      <>
                        <Table.Td align="center" pos="sticky" left={0} bg="white">
                          <Text fz="lg" fw="bold" c={primaryColor[9]}>
                            {formatPrice(getTotalPrice)}
                          </Text>
                        </Table.Td>
                        <Table.Td colSpan={3}>
                          <Text fz="sm">{refreshDate}</Text>
                        </Table.Td>
                        <Table.Td>
                          <Text fz="sm">{t('add_more_passenger_label')}</Text>
                        </Table.Td>
                      </>
                    ) : (
                      <>
                        <Table.Td>
                          <Text fz="sm">{t('add_more_passenger_label')}</Text>
                        </Table.Td>
                        <Table.Td colSpan={3}>
                          <Text fz="sm">{refreshDate}</Text>
                        </Table.Td>
                        <Table.Td align="center">
                          <Text fz="lg" fw="bold" c={primaryColor[9]}>
                            {formatPrice(getTotalPrice)}
                          </Text>
                        </Table.Td>
                      </>
                    )}
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Paper>
      ) : (
        <Skeleton>
          <Box h={96} />
        </Skeleton>
      )}
      <Group justify="space-between" my={isMobile ? 8 : 0}>
        {!isMobile && (
          <Text fz="xs">
            © {t('app_name')} {dayjs().locale(i18n.language).format('YYYY')}
          </Text>
        )}
        {/* <SlideButton
                      mainText="Refresh prices before booking"
                      overlayText={
                        isNil(bookingData)
                          ? 'Refreshing prices...'
                          : `Book on kiwi.com (${timeLeft?.minutes}:${timeLeft?.seconds})`
                      }
                      classList="btn-slide-thumb"
                      caretClassList="btn-slide-caret"
                      overlayClassList={
                        isNil(bookingData) ? 'btn-slide-overlay' : 'btn-slide-success'
                      }
                      caret={
                        isNil(bookingData) ? (
                          <IconChevronRight color="black" />
                        ) : (
                          <IconCheck color="green" />
                        )
                      }
                      onSlideDone={function () {
                        onRefreshPrices();
                      }}
                      reset={resetSlide}
                    /> */}
        {isSelectedSchedule ? (
          isNil(bookingData) ? (
            <Button
              className="btn-checkout"
              fullWidth={isMobile}
              size={isMobile ? 'md' : 'sm'}
              onClick={onRefreshPrices}
              loading={isPendingBooking}
            >
              {t('refresh_price_label')}
            </Button>
          ) : (
            <Button
              className="btn-checkout"
              fullWidth={isMobile}
              size={isMobile ? 'md' : 'sm'}
              component="a"
              href={customDeepLink}
              target="_blank"
            >
              {`${t('book_on_label')} kiwi.com (${timeLeft?.minutes}:${timeLeft?.seconds})`}
            </Button>
          )
        ) : (
          <Skeleton w={200}>
            <Box h={32} />
          </Skeleton>
        )}
      </Group>
      {isMobile && (
        <Text fz="xs" ta="center">
          © {t('app_name')} {dayjs().locale(i18n.language).format('YYYY')}
        </Text>
      )}
    </Stack>
  );
}
