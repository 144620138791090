import { Group, Image, Paper, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import {
  departDateState,
  returnDateState,
  selectedDepartScheduleState,
  selectedReturnScheduleState,
  visibleDepartState,
  visibleReturnState,
} from '../../models/scheduleState';
import { useMemo } from 'react';

import { getFlightSchduleTime, getAirlineInfo } from '../../utils/scheduleHelper';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { primaryColor } from '../../assets/themes/theme';
import { cloneDeep } from '../../utils/helper';

interface ScheduleTableSummaryProps {
  type: 'depart' | 'return';
  isLoading: boolean;
}

export default function ScheduleTableSummary({ type, isLoading }: ScheduleTableSummaryProps) {
  const departDate = useRecoilValue(departDateState);
  const returnDate = useRecoilValue(returnDateState);
  const selectedDepart = useRecoilValue(selectedDepartScheduleState);
  const selectedReturn = useRecoilValue(selectedReturnScheduleState);
  const visibleDepart = useRecoilValue(visibleDepartState);
  const visibleReturn = useRecoilValue(visibleReturnState);

  const isVisibleDepart = useMemo(() => visibleDepart === 'true', [visibleDepart]);
  const isVisibleReturn = useMemo(() => visibleReturn === 'true', [visibleReturn]);

  const { i18n, t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 37.5rem)');

  const isDepart = useMemo(() => type === 'depart', [type]);

  /**
   * get airline info by code
   */
  const airlineInfo = useMemo(
    () => getAirlineInfo(isDepart ? selectedDepart : selectedReturn),
    [isDepart, selectedDepart, selectedReturn],
  );

  const airlineInfoMultiLanguage = useMemo(() => {
    if (!airlineInfo?.name) return airlineInfo;
    const cloneAirlineInfo = cloneDeep(airlineInfo);
    cloneAirlineInfo.name = t(`airline.${cloneAirlineInfo.name}`, cloneAirlineInfo.name);
    return cloneAirlineInfo;
  }, [airlineInfo, i18n?.language]);

  const isSelectedSchedule = useMemo(() => {
    if (
      isVisibleDepart &&
      isVisibleReturn &&
      (!Object.keys(selectedDepart).length || !Object.keys(selectedReturn).length)
    )
      return false;
    else if (isVisibleDepart && !Object.keys(selectedDepart).length) return false;
    else if (isVisibleReturn && !Object.keys(selectedReturn).length) return false;
    return true;
  }, [selectedDepart, selectedReturn, isVisibleDepart, isVisibleReturn]);

  return (
    <Paper shadow="0">
      <Group px="sm" py="xs" align="center" gap={isMobile ? 8 : 48}>
        <Group gap="xs" align="center">
          {/* {isSelectedSchedule ? (
            <Image src={airlineInfoMultiLanguage?.icon} alt="airline" width={22} height={22} />
          ) : (
            ''
          )} */}
          <Text fz="sm" fw="600">
            {airlineInfoMultiLanguage?.name || ''}
          </Text>
        </Group>
        <Group gap="4" align="center">
          {isLoading ? (
            <Text fz="sm" fw="600" c={primaryColor[9]}>
              {t('please_wait')}
            </Text>
          ) : isSelectedSchedule ? (
            <>
              <Text fz="sm" fw="600" c={primaryColor[9]}>
                {isDepart ? `HKG ` : `${selectedReturn?.flyFrom} `}
                {getFlightSchduleTime(isDepart ? selectedDepart : selectedReturn).from}
              </Text>
              <IconArrowRight color={primaryColor[9]} />
              <Text fz="sm" fw="600" c={primaryColor[9]}>
                {`${getFlightSchduleTime(isDepart ? selectedDepart : selectedReturn).to} `}
                {isDepart ? ` ${selectedDepart?.flyTo}` : ' HKG'}
              </Text>
            </>
          ) : (
            <Text fz="sm" fw="600" c={primaryColor[9]}>
              {t('no_selected_schedule')}
            </Text>
          )}
        </Group>
        <Text fz="sm" fw="600">
          {dayjs(isDepart ? departDate : returnDate)
            .locale(i18n.language)
            .format('dddd, D MMMM')}
        </Text>
      </Group>
      {/* <Grid px="sm" py="xs" align="center">
        <Grid.Col span={{ sm: 2.6 }}>
          <Group gap="xs" align="center">
            <Image src={airlineInfoMultiLanguage?.icon} alt="airline" width={22} height={22} />
            <Text fz="sm" fw="600">
              {airlineInfoMultiLanguage?.name || ''}
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={{ sm: 2.6 }}>
          <Group gap="4">
            <Text fz="sm" fw="600">
              {isDepart ? `HKG ` : `${selectedReturn?.flyFrom} `}
              {getFlightSchduleTime(isDepart ? selectedDepart : selectedReturn).from}
            </Text>
            <IconArrowRight />
            <Text fz="sm" fw="600">
              {`${getFlightSchduleTime(isDepart ? selectedDepart : selectedReturn).to} `}
              {isDepart ? ` ${selectedDepart?.flyTo}` : ' HKG'}
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={{ sm: 2.6 }}>
          <Text fz="sm" fw="600">
            {dayjs(isDepart ? departDate : returnDate).format('dddd, D MMMM')}
          </Text>
        </Grid.Col>
      </Grid> */}
    </Paper>
  );
}
