import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Image,
  Paper,
  Skeleton,
  Stack,
  Table,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentTimeDepartPositionState,
  currentTimeReturnPositionState,
  departDateState,
  departScheduleState,
  destinationState,
  returnDateState,
  returnScheduleState,
  selectedDepartScheduleState,
  selectedReturnScheduleState,
  soldScheduleState,
} from '../../models/scheduleState';
import dayjs from 'dayjs';
import { memo, useCallback, useMemo, useState } from 'react';
import ScheduleTableSummary from './ScheduleTableSummary';
import { useDidUpdate, useMediaQuery, usePrevious, useViewportSize } from '@mantine/hooks';
// @ts-ignore
import logo from '../../assets/images/logoHK.png';
import { IconArrowRight, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { cloneDeep, isEmpty, isEqual, isNil, sortBy } from '../../utils/helper';
import {
  currentScheduleTimes,
  getAirportName,
  getRawSelectedSchedules,
} from '../../utils/scheduleHelper';
import ScheduleTableItem from './ScheduleTableItem';
import ScheduleTableHeader from './ScheduleTableHeader';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { primaryColor } from '../../assets/themes/theme';

interface ScheduleTableProps {
  type: 'depart' | 'return';
  visibleDepart?: string;
  visibleReturn?: string;
  setScheduleType: (value: string) => void;
}
export default function ScheduleTable({
  type,
  visibleDepart,
  visibleReturn,
  setScheduleType,
}: ScheduleTableProps) {
  const { width } = useViewportSize();
  const isMobile = useMediaQuery('(max-width: 37.5rem)');
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const isDark = useMemo(() => colorScheme === 'dark', [colorScheme]);
  const { i18n, t } = useTranslation();

  const destination = useRecoilValue(destinationState);
  const departDate = useRecoilValue(departDateState);
  const returnDate = useRecoilValue(returnDateState);
  const currentTimeDepart = useRecoilValue(currentTimeDepartPositionState);
  const currentTimeReturn = useRecoilValue(currentTimeReturnPositionState);
  const soldSchedule = useRecoilValue(soldScheduleState);

  const prevDestination = usePrevious(destination);
  const prevDepartDate = usePrevious(departDate);
  const prevReturnDate = usePrevious(returnDate);
  const prevSoldSchedule = usePrevious(soldSchedule);

  const [selectedDepart, setSelectedDepart] = useRecoilState(selectedDepartScheduleState);
  const [selectedReturn, setSelectedReturn] = useRecoilState(selectedReturnScheduleState);
  const setDepartSchedule = useSetRecoilState(departScheduleState);
  const setReturnSchedule = useSetRecoilState(returnScheduleState);

  const [showAllFlight, setShowAllFlight] = useState(true);

  const isDepart = useMemo(() => type === 'depart', [type]);

  const shouldRefetch = useMemo(() => {
    if (destination !== prevDestination) return true;
    if (!isEqual(sortBy(soldSchedule), sortBy(prevSoldSchedule))) {
      Cookies.remove('dacota-selected-depart-schedule');
      Cookies.remove('dacota-selected-return-schedule');
      return true;
    }
    if (isDepart) {
      if (prevDepartDate !== departDate) return true;
      return false;
    }
    return prevReturnDate !== returnDate;
  }, [
    isDepart,
    destination,
    prevDestination,
    departDate,
    prevDepartDate,
    returnDate,
    prevReturnDate,
    soldSchedule,
    prevSoldSchedule,
  ]);

  const currentDate = useMemo(() => {
    if (isDepart) return departDate;
    return returnDate;
  }, [isDepart, departDate, returnDate]);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [`schedules`, isDepart, destination, currentDate],
    queryFn: async () => {
      notifications.clean();
      const resData = await axios.get(`${process.env?.REACT_APP_API_URL}/flight/schedule`, {
        params: {
          flyFrom: isDepart ? 'city:HKG' : `city:${destination}`,
          flyTo: isDepart ? `city:${destination}` : 'city:HKG',
          dateFrom: dayjs(currentDate).format('DD/MM/YYYY'),
          dateTo: dayjs(currentDate).format('DD/MM/YYYY'),
          limit: 1000,
        },
        timeout: 10000,
      });
      const responseData = resData?.data?.data
        ? {
            ...resData?.data?.data,
            ...(resData?.data?.data?.rows && {
              rows: resData.data.data.rows.map((item: any) =>
                soldSchedule && soldSchedule.includes(item?.id) ? { ...item, sold: true } : item,
              ),
            }),
            ...(resData?.data?.data?.group && {
              group: resData.data.data.group.map((group: any) => {
                if (group?.route.length) {
                  group.route = group.route.map((item: any) =>
                    soldSchedule && soldSchedule.includes(item?.id)
                      ? { ...item, sold: true }
                      : item,
                  );
                }
                return group;
              }),
            }),
          }
        : {};

      const rows = responseData?.rows;
      const selectedScheduleKey = isDepart
        ? 'dacota-selected-depart-schedule'
        : 'dacota-selected-return-schedule';
      const currSelectedSchedule = Cookies.get(selectedScheduleKey);
      const defaultIndex =
        rows.findIndex((doc: any) => !doc?.sold) !== -1
          ? rows.findIndex((doc: any) => !doc?.sold)
          : 0;

      if (rows && rows.length > 0) {
        const selectedIndex = isNil(currSelectedSchedule)
          ? defaultIndex
          : rows.findIndex((row: { id: string }) => row?.id === currSelectedSchedule);
        const selectedSchedule = getRawSelectedSchedules(
          rows[selectedIndex === -1 ? defaultIndex : selectedIndex],
        );

        if (isDepart) {
          setSelectedDepart(rows.find((doc: any) => !doc?.sold) ? selectedSchedule : {});
          setDepartSchedule(rows.map((doc: any) => getRawSelectedSchedules(doc)));
        } else {
          setSelectedReturn(rows.find((doc: any) => !doc?.sold) ? selectedSchedule : {});
          setReturnSchedule(rows.map((doc: any) => getRawSelectedSchedules(doc)));
        }
      }

      return responseData;
    },
    retry: 3,
    refetchOnWindowFocus: false,
    enabled: shouldRefetch,
  });

  /**
   * Memoized function to generate a list of airline items based on provided data.
   * If no data is available or if `showAllFlight` is false, an empty array is returned.
   * If `showAllFlight` is true, returns the entire list of airline items.
   * Otherwise, returns the first six airline items.
   *
   * @param {Object} data - The data containing the group of airline items.
   * @param {boolean} showAllFlight - A boolean indicating whether to show all airline items or only the first six.
   * @returns {Array} The list of airline items based on the provided data and `showAllFlight`.
   */
  const airlineItems = useMemo(() => {
    if (!data?.group) return [];
    if (showAllFlight) return data?.group;
    if (data?.group.length <= 6) return data?.group;
    return data?.group.slice(0, 6);
  }, [data, showAllFlight]);

  /* useDidUpdate(() => {
    if (!isLoading) {
      notifications.clean();
      return;
    }
    notifications.show({
      id: 'loading-schedules',
      loading: true,
      autoClose: false,
      withCloseButton: false,
      color: 'blue',
      title: 'Please Wait...',
      message: 'Getting the airline schedules',
    });
  }, [isLoading]); */

  useDidUpdate(() => {
    if (!isError) return;
    notifications.show({
      id: 'failed-schedules',
      color: 'red',
      title: 'Something wrong!',
      message: error?.message,
    });
  }, [isError]);

  /**
   * check is schedule is selected
   */
  const isSelectedSchedule = useCallback(
    (id: string) => {
      if (isDepart) {
        return !isNil(selectedDepart?.id) && selectedDepart?.id === id;
      }
      return !isNil(selectedReturn?.id) && selectedReturn?.id === id;
    },
    [isDepart, selectedDepart, selectedReturn],
  );

  /**
   * get more flight information (airline name, lowest to highest price)
   */
  const moreFightItems = useMemo(() => {
    if (airlineItems.length <= 3) return;
    const moreFlight = airlineItems.slice(3);
    const airline = moreFlight.map((item: { airline: string }) => t(`airline.${item.airline}`));
    return airline.join(', ');
  }, [airlineItems, i18n?.language]);

  /**
   * get city name
   */
  // const cityName = useMemo(() => {
  //   if (isDepart) return selectedDepart?.cityTo || '';
  //   return selectedReturn?.cityFrom || '';
  // }, [isDepart, selectedDepart, selectedReturn]);

  const airportDepartMultiLanguage = useMemo(() => {
    return t(
      `airport.${getAirportName(selectedDepart.flyTo)}`,
      getAirportName(selectedDepart.flyTo),
    );
  }, [selectedDepart, i18n?.language]);

  const airportReturnMultiLanguage = useMemo(() => {
    return t(
      `airport.${getAirportName(selectedReturn.flyFrom)}`,
      getAirportName(selectedReturn.flyFrom),
    );
  }, [selectedReturn, i18n?.language]);

  const airlineItemsMultiLanguage = useMemo(() => {
    if (!airlineItems?.length) return airlineItems;
    let cloneAirlineItems = cloneDeep(airlineItems);
    cloneAirlineItems.map((doc: any) => {
      if (doc?.airline) doc.airline = t(`airline.${doc.airline}`, doc.airline);
      return doc;
    });
    return cloneAirlineItems;
  }, [airlineItems, i18n?.language]);

  // Remove hour columns if not existed
  const customCurrentScheduleTimes = useMemo(() => {
    if (!airlineItems?.length) return currentScheduleTimes();
    let result: string[][] = [[], []];
    let cloneAirlineItems = cloneDeep(airlineItems);
    for (const item of cloneAirlineItems) {
      for (const hour of currentScheduleTimes()[0]) {
        const find = item?.route.find((val: any) =>
          hour.includes(`${dayjs.utc(val?.local_departure).format('HH')}:`),
        );
        if (
          !!(
            process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE &&
            process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE === 'true'
          )
        ) {
          if (find && !result[0].includes(hour)) result[0].push(hour);
          continue;
        }
        if (!result[0].includes(hour)) result[0].push(hour);
      }
      for (const hour of currentScheduleTimes()[1]) {
        const find = item?.route.find((val: any) =>
          hour.includes(`${dayjs.utc(val?.local_departure).format('HH')}:`),
        );
        if (
          !!(
            process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE &&
            process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE === 'true'
          )
        ) {
          if (find && !result[1].includes(hour)) result[1].push(hour);
          continue;
        }
        if (!result[1].includes(hour)) result[1].push(hour);
      }
    }
    if (result[0]) result[0] = sortBy(result[0]);
    if (result[1]) result[1] = sortBy(result[1]);
    return result;
  }, [airlineItems]);

  return (
    <Stack gap="xs">
      {isLoading ? (
        <Skeleton height={15} width="15%" />
      ) : (
        <Group justify={isMobile ? 'space-between' : 'flex-start'} gap={20} fw={600}>
          <Group gap={5}>
            {isDepart
              ? t('city.Hong Kong', 'Hong Kong')
              : `${selectedReturn?.flyFrom ? airportReturnMultiLanguage : ''}`}
            <IconArrowRight color={primaryColor[9]} />
            {isDepart
              ? `${selectedDepart?.flyTo ? airportDepartMultiLanguage : ''}`
              : t('city.Hong Kong', 'Hong Kong')}
          </Group>
          <Group gap={10}>
            <Button
              px={0}
              variant="transparent"
              size="compact-sm"
              bg="none"
              style={{
                color:
                  visibleDepart === 'true'
                    ? 'var(--mantine-color-primary-3)'
                    : 'var(--mantine-color-primary-7)',
                textDecoration: visibleDepart === 'true' ? '' : 'underline',
              }}
              onClick={() => setScheduleType('depart')}
              disabled={!(visibleDepart === 'true')}
            >
              {t('depart_label')}
            </Button>
            <Button
              px={0}
              variant="transparent"
              size="compact-sm"
              bg="none"
              style={{
                color:
                  visibleReturn === 'true'
                    ? 'var(--mantine-color-primary-3)'
                    : 'var(--mantine-color-primary-7)',
                textDecoration: visibleReturn === 'true' ? '' : 'underline',
              }}
              onClick={() => setScheduleType('return')}
              disabled={!(visibleReturn === 'true')}
            >
              {t('return_label')}
            </Button>
          </Group>
        </Group>
      )}
      <Paper shadow="xs">
        <ScheduleTableSummary type={type} isLoading={isLoading} />
        <Divider variant="dashed" />
        <Table.ScrollContainer
          type="native"
          minWidth={
            !!(
              process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE &&
              process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE === 'true'
            ) && isMobile
              ? customCurrentScheduleTimes[1].length < 18
                ? 72 * customCurrentScheduleTimes[1].length
                : 760
              : width >= 1280
                ? '100%'
                : 1280
          }
        >
          <Table striped highlightOnHover className="table-schedule">
            <Table.Thead>
              <ScheduleTableHeaderMemo
                type={type}
                customCurrentScheduleTimes={customCurrentScheduleTimes}
              />
            </Table.Thead>
            <Table.Tbody>
              {isLoading ? (
                Array.from({ length: 6 }, (_, i) => i + 1).map((item) => (
                  <Table.Tr key={`load_${item}`}>
                    <Table.Td py="xs">
                      <Skeleton height={10} />
                    </Table.Td>
                    {customCurrentScheduleTimes[
                      customCurrentScheduleTimes[0].length > customCurrentScheduleTimes[1].length
                        ? 0
                        : 1
                    ].map((time) => (
                      <Table.Td key={`time_${time}`} py="5" px="10">
                        <Skeleton height={10} />
                      </Table.Td>
                    ))}
                  </Table.Tr>
                ))
              ) : isEmpty(airlineItemsMultiLanguage) ? (
                <Table.Tr>
                  <Table.Td colSpan={19} py="md">
                    <Stack gap="0" align="center">
                      <Text fz="sm" fw="600">
                        Sorry, we couldn’t find your trip
                      </Text>
                      <Text fz="xs">Try different dates</Text>
                    </Stack>
                  </Table.Td>
                </Table.Tr>
              ) : (
                airlineItemsMultiLanguage.map((item: any, index: number) => (
                  <Table.Tr key={item?.airline}>
                    <Table.Td
                      pos="sticky"
                      left={0}
                      bg={
                        index % 2 === 0
                          ? isDark
                            ? theme.colors.dark[6]
                            : theme.colors.gray[0]
                          : isDark
                            ? theme.colors.dark[7]
                            : 'white'
                      }
                      style={{ zIndex: 1, textAlign: 'left' }}
                      px={12}
                    >
                      {/* <Center><Image
                          src={
                            item?.icon
                              ? `${process.env.REACT_APP_STORAGE_URL}/image/${item?.icon}`
                              : logo
                          }
                          alt={item?.airline}
                          h={20}
                          w={20}
                        /> 
                      </Center>*/}
                      <Text fz="sm" fw="600" truncate="end">
                        {item?.airline || ''}
                      </Text>
                    </Table.Td>
                    {customCurrentScheduleTimes[
                      customCurrentScheduleTimes[1].length < 18
                        ? 1
                        : isDepart
                          ? currentTimeDepart
                          : currentTimeReturn
                    ].map((hour) => {
                      let [find] = item?.route.filter((val: any) =>
                        hour.includes(`${dayjs.utc(val?.local_departure).format('HH')}:`),
                      );
                      let available = item?.route.find(
                        (val: any) =>
                          hour.includes(`${dayjs.utc(val?.local_departure).format('HH')}:`) &&
                          !val?.sold,
                      );
                      if (available) find = cloneDeep(available);
                      let findDetail = data?.rows.find(
                        (val: { id: string }) => val?.id === find?.id,
                      );

                      if (!isNil(findDetail)) {
                        find.details = findDetail;
                        delete find.details.route;
                        delete find.details.__schedule__;
                      }

                      // @ts-ignore
                      const isSelected = isSelectedSchedule(find?.id);
                      return (
                        <Table.Td key={hour} align="center">
                          {find ? (
                            <ScheduleTableItemMemo
                              isSelected={isSelected}
                              isDepart={isDepart}
                              item={{ ...find, ...item }}
                            />
                          ) : (
                            <Box h={22} />
                          )}
                        </Table.Td>
                      );
                    })}
                    {!!(
                      process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE &&
                      process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE === 'true'
                    ) &&
                    !isMobile &&
                    customCurrentScheduleTimes[1].length < 18
                      ? Array(18 - customCurrentScheduleTimes[1].length)
                          .fill(18 - customCurrentScheduleTimes[1].length)
                          .map((_item, index) =>
                            index % 2 === 0 ? (
                              <Table.Td key={index} align="center">
                                <Box h={22} />
                              </Table.Td>
                            ) : (
                              ''
                            ),
                          )
                      : ''}
                  </Table.Tr>
                ))
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        {/* {isLoading || (data?.group && data.group.length <= 6) ? null : (
          <>
            <Divider />
            <Group py="3" justify="space-between">
              <Button
                size="xs"
                variant="subtle"
                rightSection={
                  showAllFlight ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />
                }
                onClick={() => setShowAllFlight(!showAllFlight)}
              >
                {showAllFlight ? t('collapse_airlines') : moreFightItems}
              </Button>
            </Group>
          </>
        )} */}
      </Paper>
    </Stack>
  );
}

const ScheduleTableItemMemo = memo(ScheduleTableItem);
const ScheduleTableHeaderMemo = memo(ScheduleTableHeader);
