import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID as string;

export const useGAPageViewTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const sendPageView = (url: string) => {
      if (window.gtag) {
        window.gtag('config', TRACKING_ID, {
          page_path: url,
        });
      }
    };

    sendPageView(location.pathname + location.search);
  }, [location]);
};
