import dayjs from 'dayjs';
import { isEmpty } from './helper';
import airport from '../assets/data/airports.json';

/**
 * Calculates the flight duration time in hours.
 *
 * @param {Object} schedule - The selected departure schedule.
 * @returns {string|number} The flight duration time in hours, or an empty string if schedule is empty.
 */
export function getFlightDurationTime(schedule: any): string | number {
  if (!schedule || isEmpty(schedule)) return '';
  const utcDeparture = dayjs(schedule.utc_departure);
  const utcArrival = dayjs(schedule.utc_arrival);
  const totalMinutes = utcArrival.diff(utcDeparture, 'minute');
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`;
}

/**
 * get flight schdule time and airport code
 *
 * @param schedules
 * @returns
 */
export function getFlightSchduleTime(schedules: any) {
  return {
    from: `${dayjs.utc(schedules?.local_departure).format('HH:mm')}`,
    to: `${dayjs.utc(schedules?.local_arrival).format('HH:mm')}`,
  };
}

/**
 * get airport name by airport code
 *
 * @param code
 * @returns
 */
export function getAirportName(code: string) {
  const find = airport.find((item) => item.code === code);
  if (find) return find.name;
  return code;
}

/**
 * get airport name by airport code
 *
 * @param code
 * @returns
 */
export function getAirlineInfo(item: any): {
  icon: string;
  name: string;
  carier: string;
  flight_no: string;
} {
  return {
    icon: `https://dacota.sgp1.cdn.digitaloceanspaces.com/image/${item?.icon}`,
    name: item?.airline,
    carier: item?.operating_carrier,
    flight_no: item?.flight_no,
  };
}

/**
 * Returns the default departure date.
 *
 * This function checks if today is Friday or Saturday.
 * If so, it returns the date of the next Friday.
 * If not, it returns the date of the Friday of this week.
 *
 * @returns {dayjs.Dayjs} A Dayjs object representing the default departure date.
 */
export function defaultDepartDate(): dayjs.Dayjs {
  const friday = dayjs().day(5);

  if (dayjs().isBefore(friday)) return friday;
  return friday.add(1, 'week');
}

/**
 * range of scheules time
 *
 * @returns
 */
export function currentScheduleTimes() {
  const first = Array.from({ length: 18 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);
  const last = Array.from({ length: 18 }, (_, i) => `${(i + 6).toString().padStart(2, '0')}:00`);

  return [first, last];
}

/**
 * parse schedule
 *
 * @param items
 * @returns
 */
export function getRawSelectedSchedules(items: any) {
  let selectedItems: any = {
    ...items.__schedule__?.route,
    ...items.__schedule__,
    details: items,
  };
  delete selectedItems.route;
  delete selectedItems.details.route;
  delete selectedItems.details.__schedule__;
  if (!selectedItems?.id) selectedItems = { ...selectedItems, ...selectedItems.details };
  return selectedItems;
}
