import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core';
import { DatePicker, DateValue } from '@mantine/dates';
import {
  IconChevronLeft,
  IconChevronRight,
  IconSquareRoundedMinus,
  IconSquareRoundedPlus,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { departDateState, returnDateState, visibleDepartState } from '../../models/scheduleState';
import { memo, useCallback, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

const DepartureCalendar = () => {
  const isMobile = useMediaQuery('(max-width: 37.5rem)');
  const isTablet = useMediaQuery('(min-width: 37.5rem) and (max-width: 64rem)');

  const { i18n, t } = useTranslation();

  const [departDate, setDepartDate] = useRecoilState<any>(departDateState);
  const [returnDate, setReturnDate] = useRecoilState<any>(returnDateState);
  const [visibleDepart, setVisibleDepart] = useRecoilState<any>(visibleDepartState);
  const [displayDate, setDisplayDate] = useState(dayjs(departDate).toDate());

  const isVisibleDepart = useMemo(() => visibleDepart === 'true', [visibleDepart]);

  const onRemoveDepart = useCallback(() => {
    setVisibleDepart('false');
    Cookies.set('dacota-visible-depart', 'false');
  }, [setVisibleDepart]);

  const onAddDepart = useCallback(() => {
    setVisibleDepart('true');
    Cookies.set('dacota-visible-depart', 'true');
  }, [setVisibleDepart]);

  const onChange = useCallback(
    (value: DateValue) => {
      setDepartDate(value);
      const dateValue = dayjs(value);
      Cookies.set('dacota-depart-date', dateValue.toISOString(), {
        expires: dayjs().endOf('day').toDate(),
      });
      if (dateValue.isAfter(dayjs(returnDate).subtract(1, 'day'))) {
        setReturnDate(dateValue.add(1, 'day').toDate());
        Cookies.set('dacota-return-date', dateValue.add(1, 'day').toISOString(), {
          expires: dayjs().endOf('day').toDate(),
        });
      }
    },
    [returnDate, setDepartDate, setReturnDate],
  );

  return (
    <Stack gap="xs">
      <Group gap="xs">
        <Text fw="600" fz="md">
          {t('depart_label')}
        </Text>
        {isVisibleDepart ? (
          <ActionIcon
            variant="subtle"
            color="gray"
            aria-label="Remove"
            size="sm"
            onClick={onRemoveDepart}
          >
            <IconSquareRoundedMinus />
          </ActionIcon>
        ) : (
          <ActionIcon
            variant="subtle"
            color="gray"
            aria-label="Add"
            size="sm"
            onClick={onAddDepart}
          >
            <IconSquareRoundedPlus />
          </ActionIcon>
        )}
      </Group>
      {isMobile && !isVisibleDepart ? null : (
        <Card shadow="xs" h="100%">
          <Stack gap="xs" align="center">
            <Group justify="space-between" w="100%" px={isTablet ? 'xs' : 0}>
              <Group gap="xs">
                <Text fw="600" fz="sm" mr="xs">
                  {dayjs(displayDate).locale(i18n.language).format('MMMM')}
                </Text>
              </Group>
              {isVisibleDepart && (
                <Group justify="flex-end" gap={0}>
                  <ActionIcon
                    size="sm"
                    variant="subtle"
                    color="#016DA9"
                    disabled={dayjs(displayDate).isSameOrBefore(dayjs(), 'month')}
                    onClick={() => setDisplayDate(dayjs(displayDate).subtract(1, 'month').toDate())}
                  >
                    <IconChevronLeft />
                  </ActionIcon>
                  <ActionIcon
                    size="sm"
                    variant="subtle"
                    color="#016DA9"
                    onClick={() => {
                      setDisplayDate(dayjs(displayDate).add(1, 'month').toDate());
                    }}
                  >
                    <IconChevronRight />
                  </ActionIcon>
                </Group>
              )}
            </Group>
            {isVisibleDepart ? (
              <DatePicker
                size="xs"
                locale={i18n.language}
                weekdayFormat="ddd"
                minDate={dayjs().locale(i18n.language).toDate()}
                date={displayDate}
                value={departDate}
                onChange={onChange}
                styles={{
                  calendarHeader: {
                    display: 'none',
                  },
                  month: {
                    width: '100%',
                  },
                }}
              />
            ) : (
              <Stack h={157.48} />
            )}
          </Stack>
        </Card>
      )}
    </Stack>
  );
};

export default memo(DepartureCalendar);
