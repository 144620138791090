import {
  ActionIcon,
  Button,
  Container,
  Group,
  Image,
  Menu,
  useComputedColorScheme,
} from '@mantine/core';
import { IconChevronDown, IconMenu2 } from '@tabler/icons-react';
import { memo, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import logo from '../../assets/images/logoHK.png';
// @ts-ignore
import logoText from '../../assets/images/logo_text.png';
// @ts-ignore
import logoTextDark from '../../assets/images/logo_text_dark.png';
import { useMediaQuery } from '@mantine/hooks';
import { LANGUAGES } from '../../utils/languages';
import { useTranslation } from 'react-i18next';
import { isNil } from '../../utils/helper';
import Cookies from 'js-cookie';

const Header = () => {
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });

  const isMobile = useMediaQuery('(max-width: 37.5rem)');
  const isDark = useMemo(() => computedColorScheme === 'dark', [computedColorScheme]);

  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState('en');

  useEffect(() => {
    const currentLang = Cookies.get('dacota-lang');
    onChangeLang(isNil(currentLang) ? 'en' : currentLang || 'en');
  }, []);

  const onChangeLang = (lang_code: string) => {
    i18n.changeLanguage(lang_code);
    Cookies.set('dacota-lang', lang_code);
    setCurrentLanguage(lang_code);
  };

  const getCurrentLanguage = useMemo(() => {
    const findLang = LANGUAGES.find((lang) => lang.code === currentLanguage);
    if (isNil(findLang)) return 'EN';
    return findLang?.symbol || 'EN';
  }, [currentLanguage]);

  return (
    <Container size={isMobile ? 'xs' : 'xl'} h="100%">
      <Group justify="space-between" align="center" h="100%">
        <Group gap="xs" flex={1}>
          <Image src={logo} height={26} alt="dacota" />
          <Image src={isDark ? logoTextDark : logoText} height={28} alt="" />
        </Group>
        <Group justify="flex-end" gap={0}>
          <Menu shadow="md" width={200} position="bottom-end">
            <Menu.Target>
              <Button variant="subtle" rightSection={<IconChevronDown />} color="black">
                {getCurrentLanguage}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              {LANGUAGES.map((lang) => (
                <Menu.Item
                  key={lang?.code}
                  onClick={() => onChangeLang(lang?.code)}
                  style={{
                    ...(currentLanguage === lang?.code && { backgroundColor: 'ButtonHighlight' }),
                  }}
                >
                  {lang?.symbol}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </Container>
  );
};

export default memo(Header);
