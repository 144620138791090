import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/languages/en.json';
import zh_CN from './assets/languages/zh-CN.json';
import zh_TW from './assets/languages/zh-TW.json';

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: en,
    },
    'zh-CN': {
      translation: zh_CN,
    },
    'zh-TW': {
      translation: zh_TW,
    },
  },
});

export default i18n;
