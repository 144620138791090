import axios from 'axios';
import dayjs from 'dayjs';
import { isNil } from '../utils/helper';

interface refreshScheduleProps {
  item: any;
  date: any;
  returnDate?: any;
}
interface updateScheduleProps {
  realSchedule: string[];
  cacheSchedule: any[];
}
interface bookingDetailInfoProps {
  id: string;
  type: string;
}

/**
 * Fetches flight schedule data based on the provided parameters.
 *
 * @param {Object} params.item - The flight information.
 * @param {Date} params.date - The flight date.
 * @param {Date} params.returnDate - The flight date.
 *
 * @returns {Promise<Object>} A promise that resolves with the flight schedule data.
 */
export function refreshSchedule({ item, date, returnDate }: refreshScheduleProps): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      let params: any = {
        flyFrom: item?.cityCodeFrom ? `city:${item?.cityCodeFrom}` : item?.flyFrom,
        flyTo: item?.cityCodeTo ? `city:${item?.cityCodeTo}` : item?.flyTo,
        dateFrom: dayjs(date).format('DD/MM/YYYY'),
        dateTo: dayjs(date).format('DD/MM/YYYY'),
        selectAirlines: item?.airlines,
        limit: 1000,
        cache: false,
      };

      if (!isNil(returnDate)) {
        params = {
          ...params,
          returnFrom: dayjs(returnDate).format('DD/MM/YYYY'),
          returnTo: dayjs(returnDate).format('DD/MM/YYYY'),
        };
      }

      const resData = await axios.get(`${process.env?.REACT_APP_API_URL}/flight/schedule`, {
        params,
        timeout: 30000,
      });

      resolve(resData?.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateAvailableSchedule({
  realSchedule,
  cacheSchedule,
}: updateScheduleProps): string[] {
  const result: string[] = [];
  if (!realSchedule?.length) return result;
  for (const doc of cacheSchedule) {
    if (!realSchedule.find((id) => id.includes(doc.id))) {
      result.push(doc.id);
    }
  }
  return result;
}

/**
 * get detail booking info
 *
 * @param param0
 * @returns
 */
export function getBookingDetailInfo({ type, id }: bookingDetailInfoProps) {
  return new Promise(async (resolve, reject) => {
    try {
      const params: any = {
        id,
        type,
      };

      const resData = await axios.get(
        `${process.env?.REACT_APP_API_URL}/flight/booking-detail-info`,
        {
          params,
          timeout: 30000,
        },
      );

      resolve(resData?.data);
    } catch (error) {
      reject(error);
    }
  });
}
