import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';

interface TimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

interface CountdownTimerProps {
  expiryDate: string | null; // ISO date string or null
  onStart?: () => void;
  onTick?: (timeLeft: TimeLeft) => void;
  onFinish?: () => void;
  shouldClear?: boolean; // Trigger to clear the countdown, optional with default false
}

const padWithZero = (num: number): string => num.toString().padStart(2, '0');

const calculateTimeLeft = (expiryDate: string): TimeLeft => {
  const now = dayjs();
  const expiry = dayjs(expiryDate);
  const difference = expiry.diff(now);

  if (difference <= 0) {
    return { days: '00', hours: '00', minutes: '00', seconds: '00' };
  }

  return {
    days: padWithZero(Math.floor(difference / (1000 * 60 * 60 * 24))),
    hours: padWithZero(Math.floor((difference / (1000 * 60 * 60)) % 24)),
    minutes: padWithZero(Math.floor((difference / 1000 / 60) % 60)),
    seconds: padWithZero(Math.floor((difference / 1000) % 60)),
  };
};

export const useCountdownTimer = ({
  expiryDate,
  onStart,
  onTick,
  onFinish,
  shouldClear = false, // Default value is false
}: CountdownTimerProps) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!expiryDate) {
      return;
    }

    if (onStart) {
      onStart();
    }

    const tick = () => {
      const newTimeLeft = calculateTimeLeft(expiryDate);
      setTimeLeft(newTimeLeft);

      if (onTick) {
        onTick(newTimeLeft);
      }

      if (
        newTimeLeft.days === '00' &&
        newTimeLeft.hours === '00' &&
        newTimeLeft.minutes === '00' &&
        newTimeLeft.seconds === '00'
      ) {
        if (onFinish) {
          onFinish();
        }

        clearInterval(intervalRef.current!);
      }
    };

    intervalRef.current = setInterval(tick, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [expiryDate, onStart, onTick, onFinish]);

  useEffect(() => {
    if (shouldClear) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setTimeLeft({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      });
    }
  }, [shouldClear]);

  return timeLeft;
};
