type PassengerType = 'adults' | 'children_over_11' | 'children_2_11' | 'infant';

interface FareDetails {
  adults?: number;
  children?: number;
  infants?: number;
}

interface TripDetails {
  fare: FareDetails;
  bags_price: any;
}

interface SelectedTrip {
  details?: TripDetails;
}

/**
 * Returns the passenger name based on the type.
 *
 * @param {string} type - The type of passenger.
 * @returns {string} - The corresponding passenger name.
 */
export function passengerName(type: string): string {
  if (type === 'adults') return 'Adults';
  if (type === 'children_over_11') return 'Children over 11';
  if (type === 'children_2_11') return 'Children 2-11';
  if (type === 'infant') return 'Children under 2';
  return type;
}

/**
 * Calculates the fare for a passenger based on the trip type and passenger type.
 *
 * @param {SelectedTrip | null} info - The selected departure trip details.
 * @returns {number} - The fare for the specified passenger type and trip type.
 */
export function passengerPrice(
  type: 'adults' | 'children' | 'infants' | 'checkedBags',
  info: SelectedTrip,
): number {
  if (type === 'checkedBags') {
    return info?.details?.bags_price && Object.keys(info.details.bags_price).length
      ? (Object.values(info.details.bags_price)[0] as number)
      : 0;
  }
  return info?.details?.fare?.[type] || 0;
}
