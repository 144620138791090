'use client';
import { AppShell, Container, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/header/Header';
import '../assets/css/component.css';
import { useMediaQuery } from '@mantine/hooks';
import { useGAPageViewTracking } from '../hooks/useGoogleAnalyticsTracking';

export default function MainLayout() {
  const isMobile = useMediaQuery('(max-width: 37.5rem)');
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });

  const isDark = useMemo(() => computedColorScheme === 'dark', [computedColorScheme]);

  // Google analytics tracking
  useGAPageViewTracking();

  return (
    <AppShell header={{ height: 48 }}>
      <AppShell.Header
        withBorder={!isDark}
        style={{ boxShadow: '0 0.08rem 0.3rem 0.01rem rgba(1, 68, 106, 0.1)' }}
      >
        <Header />
      </AppShell.Header>
      <AppShell.Main bg={isDark ? theme.colors.dark[9] : '#F6F8FA'}>
        <Container size={isMobile ? 'xs' : 'xl'} h="100%" pt="md" pb="md">
          <Outlet />
        </Container>
      </AppShell.Main>
    </AppShell>
  );
}
