import '@mantine/core/styles.css';

import { MantineProvider, Text } from '@mantine/core';
import { theme } from './assets/themes/theme';

import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Notifications } from '@mantine/notifications';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import HomePage from './pages/HomePage';
import MainLayout from './layouts/MainLayout';
import { RecoilRoot } from 'recoil';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';

import './i18n';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';

const queryClient = new QueryClient();
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />}>
      <Route
        path="/"
        element={
          <Suspense fallback={<Text>Loading...</Text>}>
            <HomePage />
          </Suspense>
        }
      />
    </Route>,
  ),
);

function App() {
  /* const { i18n } = useTranslation();

  useEffect(() => {
    console.log('c', i18n?.language);

    dayjs.locale(i18n?.language);
  }, [i18n?.language]); */

  return (
    <MantineProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <Notifications position="top-center" />
          <RouterProvider router={router} />
        </RecoilRoot>
      </QueryClientProvider>
    </MantineProvider>
  );
}

export default App;
