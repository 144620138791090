import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import {
  IconChevronLeft,
  IconChevronRight,
  IconSquareRoundedMinus,
  IconSquareRoundedPlus,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { departDateState, returnDateState, visibleReturnState } from '../../models/scheduleState';
import { memo, useCallback, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

const ReturnCalendar = () => {
  const isMobile = useMediaQuery('(max-width: 37.5rem)');
  const isTablet = useMediaQuery('(min-width: 37.5rem) and (max-width: 64rem)');

  const { i18n, t } = useTranslation();

  const departDate = useRecoilValue<any>(departDateState);
  const [returnDate, setReturnDate] = useRecoilState<any>(returnDateState);
  const [visibleReturn, setVisibleReturn] = useRecoilState<any>(visibleReturnState);
  const [displayDate, setDisplayDate] = useState(dayjs(returnDate).toDate());

  const isVisibleReturn = useMemo(() => visibleReturn === 'true', [visibleReturn]);

  /* useEffect(() => {
    if (dayjs(displayDate).isBefore(dayjs(returnDate))) {
      setDisplayDate(returnDate);
    }
  }, [returnDate, displayDate]); */

  const onRemoveReturn = useCallback(() => {
    setVisibleReturn('false');
    Cookies.set('dacota-visible-return', 'false');
  }, [setVisibleReturn]);

  const onAddReturn = useCallback(() => {
    setVisibleReturn('true');
    Cookies.set('dacota-visible-return', 'true');
  }, [setVisibleReturn]);

  return (
    <Stack gap="xs">
      <Group gap="xs">
        <Text fw="600" fz="md">
          {t('return_label')}
        </Text>
        {isVisibleReturn ? (
          <ActionIcon
            variant="subtle"
            color="gray"
            aria-label="Remove"
            size="sm"
            onClick={onRemoveReturn}
          >
            <IconSquareRoundedMinus />
          </ActionIcon>
        ) : (
          <ActionIcon
            variant="subtle"
            color="gray"
            aria-label="Add"
            size="sm"
            onClick={onAddReturn}
          >
            <IconSquareRoundedPlus />
          </ActionIcon>
        )}
      </Group>
      {isMobile && !isVisibleReturn ? null : (
        <Card shadow="xs" h="100%">
          <Stack gap="xs" align="center">
            <Group justify="space-between" w="100%" px={isTablet ? 'xs' : 0}>
              <Group gap="xs">
                <Text fw="600" fz="sm" mr="xs">
                  {dayjs(displayDate).locale(i18n.language).format('MMMM')}
                </Text>
              </Group>
              {isVisibleReturn && (
                <Group justify="flex-end" gap={0}>
                  <ActionIcon
                    size="sm"
                    variant="subtle"
                    color="blue"
                    disabled={dayjs(displayDate).isSameOrBefore(dayjs(), 'month')}
                    onClick={() => {
                      if (dayjs(displayDate).subtract(1, 'month').isBefore(dayjs())) {
                        setDisplayDate(dayjs().toDate());
                      } else {
                        setDisplayDate(dayjs(displayDate).subtract(1, 'month').toDate());
                      }
                    }}
                  >
                    <IconChevronLeft />
                  </ActionIcon>
                  <ActionIcon
                    size="sm"
                    variant="subtle"
                    color="blue"
                    onClick={() => setDisplayDate(dayjs(displayDate).add(1, 'month').toDate())}
                  >
                    <IconChevronRight />
                  </ActionIcon>
                </Group>
              )}
            </Group>
            {isVisibleReturn ? (
              <DatePicker
                locale={i18n.language}
                size="xs"
                weekdayFormat="ddd"
                className="return-calendar"
                date={displayDate}
                minDate={dayjs(departDate).toDate()}
                value={returnDate}
                onChange={(e) => {
                  setReturnDate(e);
                  Cookies.set('dacota-return-date', dayjs(e).toISOString(), {
                    expires: dayjs().endOf('day').toDate(),
                  });
                }}
                styles={{
                  calendarHeader: {
                    display: 'none',
                  },
                  month: {
                    width: '100%',
                  },
                }}
              />
            ) : (
              <Stack h={157.48} />
            )}
          </Stack>
        </Card>
      )}
    </Stack>
  );
};

export default memo(ReturnCalendar);
