/**
 * Splits an array into chunks of a specified size.
 *
 * @param {Array} array - The array to be split into chunks.
 * @param {number} size - The size of each chunk.
 * @returns {Array<Array>} An array containing the chunks.
 *
 * @example
 *
 * const myArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
 * const chunkedArray = chunkArray(myArray, 3);
 * console.log(chunkedArray);
 * // Output: [[1, 2, 3], [4, 5, 6], [7, 8, 9]]
 */
export function chunkArray(array: any[], size: number) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
}

/**
 * Checks if a given value is empty.
 *
 * For strings, it checks if the string length is 0.
 * For arrays, it checks if the array length is 0.
 * For objects, it checks if the object has no own properties.
 * For other types, it checks if the value is null or undefined.
 *
 * @param {*} value - The value to check for emptiness.
 * @returns {boolean} True if the value is empty, false otherwise.
 *
 * @example
 *
 * isEmpty(""); // true
 * isEmpty([]); // true
 * isEmpty({}); // true
 * isEmpty(null); // true
 * isEmpty(undefined); // true
 * isEmpty("Hello"); // false
 * isEmpty([1, 2, 3]); // false
 * isEmpty({ key: "value" }); // false
 */
export function isEmpty(value: any): boolean {
  if (value == null) {
    return true;
  }
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }
  return false;
}

/**
 * Checks if a given value is null or undefined.
 *
 * @param {*} value - The value to check.
 * @returns {boolean} True if the value is null or undefined, false otherwise.
 *
 * @example
 *
 * isNil(null); // true
 * isNil(undefined); // true
 * isNil(0); // false
 * isNil(''); // false
 * isNil([]); // false
 * isNil({}); // false
 */
export function isNil(value: any): boolean {
  return value == null;
}

/**
 * format number to price
 *
 * @param price
 * @param param1
 * @returns
 */
export function formatPrice(price: number | string, options?: { digit?: number }): string {
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: options?.digit || 0,
  });
}

/**
 * Performs a deep clone of a value. The value can be an object or an array.
 *
 * @param {*} value - The value to deep clone. It can be of any type.
 * @returns {*} - The deep cloned value. If the input is not an object or array, it returns the value itself.
 * @throws {Error} - Throws an error if the value type is not supported.
 */
export function cloneDeep(value: any): any {
  if (value === null || typeof value !== 'object') {
    return value; // Return the value if it's not an object
  }

  // Handle Array
  if (Array.isArray(value)) {
    const copy = [];
    for (let i = 0; i < value.length; i++) {
      copy[i] = cloneDeep(value[i]);
    }
    return copy;
  }

  // Handle Object
  if (value instanceof Object) {
    const copy: any = {};
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        copy[key] = cloneDeep(value[key]);
      }
    }
    return copy;
  }

  throw new Error("Unable to copy value! Its type isn't supported.");
}

/**
 * This method is invoked for each element in `array` to generate the criterion by which uniqueness is computed. The order of result values is determined by the order they occur in the array.
 *
 * @param {Array} array The array to inspect.
 * @param {String|Array[]} [string] The iteratee invoked per element.
 * @returns {Array} Returns the new duplicate free array.
 * @example
 *
 *
 * uniqBy([{ 'x': 1 }, { 'x': 2 }, { 'x': 1 }], 'x');
 * // => [{ 'x': 1 }, { 'x': 2 }]
 */
export function uniqBy(arrObj: any[], uniqKeys: string | string[]): any[] {
  if (typeof arrObj !== 'object') return [];
  uniqKeys = typeof uniqKeys === 'string' ? (uniqKeys as string).split(',') : uniqKeys;

  return arrObj.filter((obj, index, arr) => {
    return (
      arr.findIndex((doc) => {
        const found = [];
        for (const uniqKey of uniqKeys) {
          if (doc[uniqKey] === obj[uniqKey]) {
            found.push(true);
            continue;
          }
          found.push(false);
        }
        return !found.includes(false);
      }) === index
    );
  });
}

export function uniq(array: any[]) {
  const seen = new Set();
  return array.filter((item) => {
    if (seen.has(item)) {
      return false;
    } else {
      seen.add(item);
      return true;
    }
  });
}

export function isEqual(value: any, other: any) {
  if (value === other) return true;

  if (value === null || typeof value !== 'object' || other === null || typeof other !== 'object') {
    return false;
  }

  const keysA = Object.keys(value);
  const keysB = Object.keys(other);

  if (keysA.length !== keysB.length) return false;

  for (let key of keysA) {
    if (!keysB.includes(key) || !isEqual(value[key], other[key])) {
      return false;
    }
  }

  return true;
}

export function sortBy(array: any, iteratee?: any) {
  // Jika tidak ada iteratee yang diberikan, gunakan identity function (kembalikan nilai elemen itu sendiri)
  const iterateeFn = iteratee || ((x: any) => x);

  return array.slice().sort((a: any, b: any) => {
    const aValue = iterateeFn(a);
    const bValue = iterateeFn(b);

    if (aValue > bValue) {
      return 1;
    } else if (aValue < bValue) {
      return -1;
    } else {
      return 0;
    }
  });
}
