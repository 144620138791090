import { Flex, Stack, Text } from '@mantine/core';
import DestinationPicker from '../components/destination/DestinationPicker';
import DepartureCalendar from '../components/departure/DepartureCalendar';
import ReturnCalendar from '../components/return/ReturnCalendar';
import ScheduleTable from '../components/schedules/ScheduleTable';
import CheckoutTable from '../components/checkout/CheckoutTable';
import { memo, useEffect, useState } from 'react';
import { visibleDepartState, visibleReturnState } from '../models/scheduleState';
import { useRecoilValue } from 'recoil';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

export default function HomePage() {
  const visibleDepart = useRecoilValue<any>(visibleDepartState);
  const visibleReturn = useRecoilValue<any>(visibleReturnState);
  const isMobile = useMediaQuery('(max-width: 37.5rem)');
  const isTablet = useMediaQuery('(min-width: 37.5rem) and (max-width: 64rem)');
  const { t } = useTranslation();

  const [scheduleType, setScheduleType] = useState(
    visibleDepart === 'true' && visibleReturn === 'true'
      ? 'depart'
      : visibleDepart === 'true'
        ? 'depart'
        : 'return',
  );

  useEffect(() => {
    if (visibleReturn === 'true') return setScheduleType('return');
    setScheduleType('depart');
  }, [visibleDepart, visibleReturn]);

  return (
    <Stack px="6" gap="xl">
      <Flex {...((isMobile || isTablet) && { direction: 'column' })} gap="lg">
        <Stack gap="xs">
          <Text fz="md" fw="600">
            {t('destination_label')}
          </Text>
          <DestinationPicker />
        </Stack>
        <Stack w={isMobile || isTablet ? '100%' : 520}>
          <Flex
            align="flex-start"
            justify="center"
            gap="lg"
            {...(isMobile && { direction: 'column' })}
          >
            <Stack w={isMobile ? '100%' : isTablet ? '50%' : 280}>
              <DepartureCalendar />
            </Stack>
            <Stack w={isMobile ? '100%' : isTablet ? '50%' : 280}>
              <ReturnCalendar />
            </Stack>
          </Flex>
        </Stack>
      </Flex>
      {visibleDepart === 'true' && scheduleType === 'depart' && (
        <ScheduleTableMemo
          type="depart"
          visibleReturn={visibleReturn}
          setScheduleType={setScheduleType}
        />
      )}
      {visibleReturn === 'true' && scheduleType === 'return' && (
        <ScheduleTableMemo
          type="return"
          visibleDepart={visibleDepart}
          setScheduleType={setScheduleType}
        />
      )}
      <CheckoutTableMemo />
    </Stack>
  );
}

const ScheduleTableMemo = memo(ScheduleTable);
const CheckoutTableMemo = memo(CheckoutTable);
