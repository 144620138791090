'use client';

import { MantineColorsTuple, createTheme } from '@mantine/core';

export const primaryColor: MantineColorsTuple = [
  '#e6f7ff',
  '#d0ebff',
  '#9fd5fc',
  '#6bbdfb',
  '#45a9fa',
  '#309dfa',
  '#d0ebff',
  //'#2496fb',
  '#1682e0',
  '#0074c9',
  '#016DA9',
];

export const theme = createTheme({
  fontFamily: 'Poppins, sans-serif',
  colors: {
    primary: primaryColor,
    gray: [
      '#f8f9fa',
      '#f1f3f5',
      '#e9ecef',
      '#D5D9E0',
      '#ced4da',
      '#adb5bd',
      '#868e96',
      '#495057',
      '#343a40',
      '#212529',
    ],
  },
  primaryColor: 'primary',
  autoContrast: true,
  luminanceThreshold: 0.79,
  defaultRadius: 'md',
  shadows: {
    xs: '0 0.08rem 0.3rem 0.03rem rgba(1, 68, 106, 0.2)',
  },
});
