import { atom } from 'recoil';
import Cookies from 'js-cookie';
import { defaultDepartDate } from '../utils/scheduleHelper';

export const destinationState = atom({
  key: 'destinationState',
  default: Cookies.get('dacota-destination') || 'BKK',
});

export const visibleDepartState = atom({
  key: 'visibleDepartState',
  default: Cookies.get('dacota-visible-depart') || 'true',
});

export const visibleReturnState = atom({
  key: 'visibleReturnState',
  default: Cookies.get('dacota-visible-return') || 'true',
});

export const departDateState = atom({
  key: 'departDateState',
  default: Cookies.get('dacota-depart-date') || defaultDepartDate().toDate(),
});

export const returnDateState = atom({
  key: 'returnDateState',
  default: Cookies.get('dacota-return-date') || defaultDepartDate().add(2, 'day').toDate(),
});

export const selectedDepartScheduleState = atom<any>({
  key: 'selectedDepartScheduleState',
  default: {},
});

export const selectedReturnScheduleState = atom<any>({
  key: 'selectedReturnSchedule',
  default: {},
});

export const departScheduleState = atom<any>({
  key: 'departScheduleState',
  default: [],
});

export const returnScheduleState = atom<any>({
  key: 'returnScheduleState',
  default: [],
});

export const soldScheduleState = atom<string[]>({
  key: 'soldScheduleState',
  default: Cookies.get('dacota-sold-schedule')
    ? JSON.parse(Cookies.get('dacota-sold-schedule')!)
    : [],
});

export const currentTimeDepartPositionState = atom<any>({
  key: 'currentTimeDepartPositionState',
  default: parseInt(Cookies.get('dacota-current-time-depart-position') || '1'),
});
export const currentTimeReturnPositionState = atom<any>({
  key: 'currentTimeReturnPositionState',
  default: parseInt(Cookies.get('dacota-current-time-return-position') || '1'),
});
