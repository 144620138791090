import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Flex, Select } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useRecoilState } from 'recoil';
import Cookies from 'js-cookie';
import airports from '../../assets/data/airports.json';
import { destinationState } from '../../models/scheduleState';
import { cloneDeep, uniqBy } from '../../utils/helper';

export default function DestinationPicker() {
  const isMobile = useMediaQuery('(max-width: 37.5rem)');

  const [destination, setDestination] = useRecoilState(destinationState);

  const destinationInit = [
    'BKK',
    'MNL',
    'TPE',
    'KHH',
    'SIN',
    'SHA',
    'BJS',
    'SEL',
    'TYO',
    'OSA',
    'SGN',
  ];
  const cloneAirports = cloneDeep(airports);

  const { i18n, t } = useTranslation();

  const airportMultiLanguage = useMemo(() => {
    return cloneAirports.map((doc: any) => {
      if (doc?.name) doc.name = t(`airport.${doc.name}`, doc.name);
      if (doc?.city) doc.city = t(`city.${doc.city}`, doc.city);
      if (doc?.country) doc.country = t(`country.${doc.country}`, doc.country);
      return doc;
    });
  }, [i18n?.language]);

  if (isMobile) {
    return (
      <Select
        size="md"
        value={destination}
        data={uniqBy(airportMultiLanguage, 'city')
          .sort((a, b) => a.city.localeCompare(b.city))
          .filter((item) => destinationInit.includes(item?.code))
          .map((item) => ({
            value: item?.code,
            label: item?.city,
            disabled: !destinationInit.includes(item?.code),
          }))}
        onChange={(val) => {
          setDestination(val || '');
          Cookies.set('dacota-destination', val || '');
        }}
      />
    );
  }
  return (
    <Card shadow="xs" h="100%" px="xs" py="sm">
      <Flex wrap="wrap" rowGap="2" columnGap="10" gap="4">
        {uniqBy(airportMultiLanguage, 'city')
          .sort((a, b) => a.city.localeCompare(b.city))
          .filter((item) => destinationInit.includes(item?.code))
          .map((val) => (
            <Button
              {...(destination !== val?.code && { color: 'dark' })}
              key={val?.code}
              variant={destination === val?.code ? 'filled' : 'subtle'}
              radius="6"
              size="compact-md"
              disabled={!destinationInit.includes(val?.code)}
              onClick={() => {
                setDestination(val?.code);
                Cookies.set('dacota-destination', val?.code);
              }}
              className="btn-destination"
              styles={{
                label: {
                  fontSize: 14,
                  fontWeight: destination === val?.code ? 800 : 'normal',
                },
              }}
            >
              {val?.city}
            </Button>
          ))}
      </Flex>
    </Card>
  );
}
