import { ActionIcon, Box, Center, Table, Text } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  currentTimeDepartPositionState,
  currentTimeReturnPositionState,
  selectedDepartScheduleState,
  selectedReturnScheduleState,
} from '../../models/scheduleState';

import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useDidUpdate, useMediaQuery } from '@mantine/hooks';

interface ScheduleTableHeaderProps {
  type: 'depart' | 'return';
  customCurrentScheduleTimes: string[][];
}

export default function ScheduleTableHeader({
  type,
  customCurrentScheduleTimes,
}: ScheduleTableHeaderProps) {
  const isMobile = useMediaQuery('(max-width: 37.5rem)');

  const [currentDepartTime, setCurrentDepartTime] = useRecoilState(currentTimeDepartPositionState);
  const [currentReturnTime, setCurrentReturnTime] = useRecoilState(currentTimeReturnPositionState);

  const selectedDepart = useRecoilValue(selectedDepartScheduleState);
  const selectedReturn = useRecoilValue(selectedReturnScheduleState);

  const isDepart = useMemo(() => type === 'depart', [type]);

  const currentTime = useMemo(() => {
    if (isDepart) return currentDepartTime;
    return currentReturnTime;
  }, [isDepart, currentDepartTime, currentReturnTime]);

  useDidUpdate(() => {
    if (isDepart) {
      const hour = dayjs(selectedDepart?.utc_departure).format('H');
      setCurrentDepartTime(parseInt(hour) < 6 ? 0 : 1);
    } else {
      const hour = dayjs(selectedReturn?.utc_departure).format('H');
      setCurrentReturnTime(parseInt(hour) < 6 ? 0 : 1);
    }
  }, [isDepart, selectedDepart, selectedReturn]);

  return (
    <Table.Tr>
      <Table.Th pos="sticky" left={0} bg="white">
        {customCurrentScheduleTimes[1].length < 18 ? (
          <Box h={20} />
        ) : (
          <Center>
            <ActionIcon
              size="xs"
              variant="subtle"
              color="dark"
              opacity={currentTime === 0 ? 0.3 : 1}
              onClick={() => {
                if (isDepart && currentTime !== 0) {
                  setCurrentDepartTime(0);
                } else if (!isDepart && currentTime !== 0) {
                  setCurrentReturnTime(0);
                }
              }}
            >
              <IconChevronLeft size={18} stroke={3} />
            </ActionIcon>
            <ActionIcon
              size="xs"
              variant="subtle"
              color="dark"
              opacity={currentTime === 1 ? 0.3 : 1}
              onClick={() => {
                if (isDepart && currentTime !== 1) {
                  setCurrentDepartTime(1);
                } else if (!isDepart && currentTime !== 1) {
                  setCurrentReturnTime(1);
                }
              }}
            >
              <IconChevronRight size={18} stroke={3} />
            </ActionIcon>
          </Center>
        )}
      </Table.Th>
      {customCurrentScheduleTimes[customCurrentScheduleTimes[1].length < 18 ? 1 : currentTime].map(
        (item) => (
          <Table.Th key={item} fz="xs" align="center" w={60}>
            {item}
          </Table.Th>
        ),
      )}
      {!!(
        process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE &&
        process.env?.REACT_APP_ONLY_SCHEDULE_PRICE_AVAILABLE === 'true'
      ) &&
      !isMobile &&
      customCurrentScheduleTimes[1].length < 18
        ? Array(18 - customCurrentScheduleTimes[1].length)
            .fill(18 - customCurrentScheduleTimes[1].length)
            .map((_item, index) =>
              index % 2 === 0 ? (
                <Table.Th key={index} fz="xs" align="center" w={60}>
                  <Box h={22} />
                </Table.Th>
              ) : (
                ''
              ),
            )
        : ''}
      {/* <Table.Th pos="sticky" right={0}>
        <Center>
          <ActionIcon
            size="xs"
            variant="subtle"
            color="dark"
            opacity={currentTime === 1 ? 0.3 : 1}
            onClick={() => {
              if (isDepart && currentTime !== 1) {
                setCurrentDepartTime(1);
              } else if (!isDepart && currentTime !== 1) {
                setCurrentReturnTime(1);
              }
            }}
          >
            <IconChevronRight size={18} />
          </ActionIcon>
        </Center>
      </Table.Th> */}
    </Table.Tr>
  );
}
