import { Button, useMantineColorScheme } from '@mantine/core';
import dayjs from 'dayjs';
import { primaryColor } from '../../assets/themes/theme';
// @ts-ignore
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';
import {
  selectedDepartScheduleState,
  selectedReturnScheduleState,
} from '../../models/scheduleState';
import { formatPrice } from '../../utils/helper';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

export default function ScheduleTableItem({ item, isSelected, isDepart }: any) {
  const { colorScheme } = useMantineColorScheme();
  const isDark = useMemo(() => colorScheme === 'dark', [colorScheme]);
  const selectedItemDepartRef = useRef(null);
  const selectedItemReturnRef = useRef(null);

  const { i18n, t } = useTranslation();

  const [, setSelectedDepart] = useRecoilState(selectedDepartScheduleState);
  const [, setSelectedReturn] = useRecoilState(selectedReturnScheduleState);

  useEffect(() => {
    if (selectedItemDepartRef.current) {
      // @ts-ignore
      selectedItemDepartRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (selectedItemReturnRef.current) {
        // @ts-ignore
        selectedItemReturnRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }, 300);
  }, []);

  /**
   * handle on select schedule
   */
  const onSelectedSchedule = useCallback(
    (item: any) => {
      delete item?.route;
      if (isDepart) {
        setSelectedDepart(item);
        Cookies.set('dacota-selected-depart-schedule', item?.id);
        return;
      }
      setSelectedReturn(item);
      Cookies.set('dacota-selected-return-schedule', item?.id);
    },
    [isDepart, setSelectedDepart, setSelectedReturn],
  );

  const isDisabled = useMemo(() => {
    // Check the schedule availability
    if (item?.sold) return true;
    // Check days
    const date = dayjs(item?.utc_departure);
    const now = dayjs();
    const isToday = date.isSame(now, 'day');
    if (!isToday) {
      return false;
    }
    // Check if the provided time is less than the current time
    const isTimeLessThanNow = date.isBefore(now, 'minute');
    return isTimeLessThanNow;
  }, [item]);

  const txtSoldMultiLanguage = useMemo(() => {
    return t('sold_label').toUpperCase();
  }, [item?.sold, i18n?.language]);

  return (
    <>
      <Button
        w={60}
        h={30}
        ref={isSelected ? (isDepart ? selectedItemDepartRef : selectedItemReturnRef) : null}
        disabled={isDisabled}
        size="compact-xs"
        radius="lg"
        className="btn-primary"
        variant={isSelected ? 'filled' : 'subtle'}
        color={isSelected ? (isDark ? primaryColor[7] : primaryColor[9]) : isDark ? 'gray' : 'dark'}
        onClick={() => onSelectedSchedule(item)}
        fw={isSelected ? 'bold' : 'normal'}
      >
        {item?.sold ? txtSoldMultiLanguage : formatPrice(item?.price)}
      </Button>
      {/* <Text size='xs'>{item?.id.toString().substring(16)}</Text> */}
    </>
  );
}
